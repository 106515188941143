import { Breadcrumb, Card, Space, Spin, Typography } from "antd";
import classNames from "classnames";
import LinkWithParams from "components/elements/LinkWithParams";
import { useLocation } from "react-router-dom";
import styles from "./View.module.less";

interface Props {
    children: React.ReactNode | null;
    title?: string | React.ReactNode;
    withGreyBackground?: boolean;
    center?: boolean;
    withBreadcrumbs?: boolean;
    isLoading?: boolean;
}

const breadcrumbNameMap: { [key: string]: string } = {
    "/": "Terminportal",
    "/kontakt": "Kontakt",
};

const { Title } = Typography;

function View({
    children,
    title,
    withGreyBackground,
    center = false,
    withBreadcrumbs = true,
    isLoading = false,
}: Props): JSX.Element {
    const location = useLocation();

    const pathSnippets = location.pathname.split("/").filter((i) => i);

    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        return (
            <Breadcrumb.Item key={url}>
                <LinkWithParams to={url}>
                    {breadcrumbNameMap[url]}
                </LinkWithParams>
            </Breadcrumb.Item>
        );
    });

    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <LinkWithParams to="/">Home</LinkWithParams>
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);

    return (
        <Card
            className={classNames({
                [styles.view]: true,
                [styles.center]: center,
                [styles.grey_view]: withGreyBackground,
            })}
            headStyle={{ textAlign: "center" }}
            title={
                title && (
                    <Space direction="vertical">
                        <Title level={4}>{title}</Title>
                        {withBreadcrumbs && (
                            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
                        )}
                    </Space>
                )
            }
            bordered
        >
            {isLoading ? (
                <div className={styles.spin_wrapper}>
                    <Spin />
                </div>
            ) : (
                children
            )}
        </Card>
    );
}

export default View;
