import { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import i18n from "locales/i18n";
import { locales } from "config/locales.config";

import styles from "./LangSelector.module.less";
import { MenuItemType } from "antd/es/menu/hooks/useItems";

interface Props {
    langFromApi?: string | null;
}

function LangSelector({ langFromApi }: Props): JSX.Element {
    const initialLanguage = i18n.language;

    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const onLanguageChange = (language: string) => {
        i18n.changeLanguage(language, () => {
            setSelectedLanguage(language);
            setDropdownVisible(false);
        });
    };

    useEffect(() => {
        if (langFromApi) {
            onLanguageChange(langFromApi);
        }
    }, [langFromApi]);

    const [selectedLanguage, setSelectedLanguage] =
        useState<string>(initialLanguage);
    console.log("LANG SELECTOR selectedLanguage", selectedLanguage);

    const language = locales.find((item) => item.prefix === selectedLanguage);

    const menuItems: MenuItemType[] = [];

    locales.map(({ prefix, name, Icon }) =>
        menuItems.push({
            key: prefix,
            icon: (
                <span className={styles.icon}>
                    <Icon />
                </span>
            ),
            label: <span>{name}</span>,
            onClick: () => onLanguageChange(prefix),
        })
    );
    return (
        <Dropdown
            trigger={["click"]}
            open={dropdownVisible}
            onOpenChange={() => setDropdownVisible(!dropdownVisible)}
            overlay={<Menu theme="light" items={menuItems} />}
        >
            <div className={styles.dropdown_wrapper}>
                {language && <language.Icon />}
                <p className={styles.user_name}>{language?.name}</p>
                {dropdownVisible ? (
                    <CaretUpOutlined className={styles.caret_icon} />
                ) : (
                    <CaretDownOutlined className={styles.caret_icon} />
                )}
            </div>
        </Dropdown>
    );
}

export default LangSelector;
