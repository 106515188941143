import { useEffect, useState } from "react";
import { checkIfIsExpired } from "helpers/checkIsExpired";
import { useGetParticipantInfoQuery } from "store/terminportal";

export function useIsExpired() {
    const [isExpired, setIsExpired] = useState(false);
    const { data: participantData, isLoading: participantDataLoading } =
        useGetParticipantInfoQuery();

    useEffect(() => {
        if (participantData) {
            const participantInfo = participantData?.value;
            const newValue = checkIfIsExpired(
                participantInfo?.participantEndDate
            );
            setIsExpired(newValue);
        } else if (!participantDataLoading) {
            setIsExpired(false);
        }
    }, [participantData, participantDataLoading]);

    return isExpired;
}
