import { IconProps } from "types/icon.types";

function UKFlagIcon(props: IconProps): JSX.Element {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <title>icon / flag / uk</title>
            <desc>Created with Sketch.</desc>
            <g
                id="icon-/-flag-/-uk"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g id="UK" fill-rule="nonzero">
                    <circle id="Oval" fill="#E9E9E9" cx="8" cy="8" r="8" />
                    <g
                        id="Group"
                        transform="translate(0.250000, 0.250000)"
                        fill="#5F85DA"
                    >
                        <path
                            d="M1.40375,2.8794375 C0.77534375,3.69703125 0.3015,4.639375 0.02559375,5.66309375 L4.18740625,5.66309375 L1.40375,2.8794375 Z"
                            id="Path"
                        />
                        <path
                            d="M15.4744062,5.66309375 C15.1985,4.63940625 14.724625,3.6970625 14.09625,2.87946875 L11.3126562,5.66309375 L15.4744062,5.66309375 Z"
                            id="Path"
                        />
                        <path
                            d="M0.02559375,9.837 C0.30153125,10.8606875 0.775375,11.8030313 1.40375,12.6205937 L4.1873125,9.837 L0.02559375,9.837 L0.02559375,9.837 Z"
                            id="Path"
                        />
                        <path
                            d="M12.6205625,1.40378125 C11.8029687,0.775375 10.8606562,0.30153125 9.8369375,0.02559375 L9.8369375,4.187375 L12.6205625,1.40378125 Z"
                            id="Path"
                        />
                        <path
                            d="M2.8794375,14.0962188 C3.69703125,14.724625 4.639375,15.1984687 5.6630625,15.4744062 L5.6630625,11.3126562 L2.8794375,14.0962188 Z"
                            id="Path"
                        />
                        <path
                            d="M5.66303125,0.02559375 C4.63934375,0.30153125 3.697,0.775375 2.8794375,1.40375 L5.66303125,4.18734375 L5.66303125,0.02559375 Z"
                            id="Path"
                        />
                        <path
                            d="M9.83696875,15.4744062 C10.8606562,15.1984687 11.803,14.724625 12.6205625,14.09625 L9.83696875,11.3126562 L9.83696875,15.4744062 Z"
                            id="Path"
                        />
                        <path
                            d="M11.3126562,9.837 L14.09625,12.620625 C14.724625,11.8030625 15.1985,10.8606875 15.4744062,9.837 L11.3126562,9.837 Z"
                            id="Path"
                        />
                    </g>
                    <g id="Group" fill="#FF4F5D">
                        <path
                            d="M15.9322813,6.95653125 L9.04353125,6.95653125 L9.0435,6.95653125 L9.0435,0.06771875 C8.70190625,0.02325 8.35365625,0 8,0 C7.64628125,0 7.29809375,0.02325 6.95653125,0.06771875 L6.95653125,6.95646875 L6.95653125,6.9565 L0.06771875,6.9565 C0.02325,7.29809375 0,7.64634375 0,8 C0,8.35371875 0.02325,8.70190625 0.06771875,9.04346875 L6.95646875,9.04346875 L6.9565,9.04346875 L6.9565,15.9322813 C7.29809375,15.97675 7.64628125,16 8,16 C8.35365625,16 8.70190625,15.9767813 9.04346875,15.9322813 L9.04346875,9.04353125 L9.04346875,9.0435 L15.9322813,9.0435 C15.97675,8.70190625 16,8.35371875 16,8 C16,7.64634375 15.97675,7.29809375 15.9322813,6.95653125 Z"
                            id="Path"
                        />
                        <path
                            d="M10.0869688,10.0869688 L10.0869688,10.0869688 L13.6568438,13.656875 C13.8210313,13.49275 13.9776563,13.3211562 14.1270938,13.1432812 L11.0707812,10.0869688 L10.0869688,10.0869688 L10.0869688,10.0869688 Z"
                            id="Path"
                        />
                        <path
                            d="M5.91303125,10.087 L5.91296875,10.087 L2.343125,13.6568438 C2.50725,13.8210313 2.67884375,13.9776562 2.85671875,14.1270938 L5.91303125,11.0707188 L5.91303125,10.087 Z"
                            id="Path"
                        />
                        <path
                            d="M5.91303125,5.91309375 L5.91303125,5.91303125 L2.34315625,2.343125 C2.17896875,2.50725 2.02234375,2.67884375 1.87290625,2.85671875 L4.92925,5.91309375 L5.91303125,5.91309375 L5.91303125,5.91309375 Z"
                            id="Path"
                        />
                        <path
                            d="M10.0869688,5.91309375 L10.0869688,5.91309375 L13.656875,2.34315625 C13.49275,2.17896875 13.3211562,2.02234375 13.1432812,1.8729375 L10.0869688,4.92928125 L10.0869688,5.91309375 L10.0869688,5.91309375 Z"
                            id="Path"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default UKFlagIcon;
