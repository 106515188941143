import React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import "locales/i18n";
import "styles/global.less";
import "styles/link-theme.less";

import App from "./App";
import { store } from "store/root";
import { Location as RouterLocation } from "@remix-run/router/dist/history";

const container = document.getElementById("root");

interface RouteAdapterProps {
    children: React.FunctionComponent<{
        history: {
            replace(location: RouterLocation): void;
            push(location: RouterLocation): void;
        };
        location: RouterLocation;
    }>;
}

function RouteAdapter({ children }: RouteAdapterProps): JSX.Element | null {
    const navigate = useNavigate();
    const routerLocation = useLocation();

    const adaptedHistory = React.useMemo(
        () => ({
            replace(location: RouterLocation) {
                if (navigate) {
                    navigate(location, {
                        replace: true,
                        state: location.state,
                    });
                }
            },
            push(location: RouterLocation) {
                if (navigate) {
                    navigate(location, {
                        replace: false,
                        state: location.state,
                    });
                }
            },
        }),
        [navigate]
    );

    if (!children) {
        return null;
    }

    return children({ history: adaptedHistory, location: routerLocation });
}

if (container) {
    const root = ReactDOM.createRoot(container);
    root.render(
        <React.StrictMode>
            <ConfigProvider locale={en}>
                <Provider store={store}>
                    <BrowserRouter>
                        <QueryParamProvider
                            ReactRouterRoute={
                                RouteAdapter as React.FunctionComponent
                            }
                        >
                            <App />
                        </QueryParamProvider>
                    </BrowserRouter>
                </Provider>
            </ConfigProvider>
        </React.StrictMode>
    );
}
