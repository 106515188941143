import { Navigate } from "react-router-dom";

type Params = {
    isAuthorized: boolean | null;
    survey: string | null | undefined;
    children: JSX.Element;
};

const ProtectedRoute = ({ isAuthorized, survey, children }: Params) => {
    if (typeof isAuthorized === "boolean" && !isAuthorized) {
        return <Navigate to={`/login?survey=${survey}`} replace />;
    }
    if (isAuthorized) {
        return children;
    }

    return null;
};

export default ProtectedRoute;
