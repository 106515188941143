import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./de-CH.json";
import fr from "./fr-CH.json";
import it from "./it-CH.json";
import en from "./en-US.json";
import detector from "i18next-browser-languagedetector";

const resources = {
    "de-CH": {
        translation: de,
    },
    "fr-CH": {
        translation: fr,
    },
    "it-CH": {
        translation: it,
    },
    "en-US": {
        translation: en,
    },
};

const options = {
    order: ["querystring", "localStorage", "navigator"],
    lookupQuerystring: "lng",
};

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        detection: options,
        fallbackLng: "de-CH",
        debug: false,
        supportedLngs: ["de-CH", "fr-CH", "it-CH", "en-US"],
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
