import { IconProps } from "types/icon.types";

function ITFlagIcon(props: IconProps): JSX.Element {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <title>icon / flag / it</title>
            <desc>Created with Sketch.</desc>
            <g
                id="icon-/-flag-/-it"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g id="IT">
                    <path
                        d="M5.33333898,0.455213226 L5.33333898,15.5447868 C2.22615637,14.4465579 -3.26849658e-13,11.4832523 -3.26849658e-13,8 C-3.26849658e-13,4.51674769 2.22615637,1.55344205 5.33333898,0.455213226 Z"
                        id="Combined-Shape"
                        fill="#5DC34B"
                    />
                    <path
                        d="M8,0 C8.93502569,0 9.83258548,0.160410533 10.666661,0.455213226 L10.666661,15.5447868 C9.83258548,15.8395895 8.93502569,16 8,16 L8.06212072,15.999 L7.93778397,15.9997631 C7.02507318,15.992811 6.148742,15.8330164 5.33312072,15.5447096 L5.33312072,0.455290373 C6.16725793,0.160438518 7.06489276,0 8,0 Z"
                        id="Combined-Shape"
                        fill="#E9E9E9"
                    />
                    <path
                        d="M10.6668793,0.455290373 C13.7739479,1.55358082 16,4.51682925 16,8 C16,11.4831708 13.7739479,14.4464192 10.6668793,15.5447096 Z"
                        id="Combined-Shape"
                        fill="#FF5F63"
                    />
                </g>
            </g>
        </svg>
    );
}

export default ITFlagIcon;
