import { Col, Layout, Row, Space } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "assets/logo.icon.png";

import styles from "./Header.module.less";
import UserMenu from "../UserMenu";
import LangSelector from "../LangSelector";
import { useQuery } from "hooks/useQuery";
import {
    useGetDisplayInfoQuery,
    useGetParticipantInfoQuery,
} from "store/terminportal";
import { StringParam, useQueryParams } from "use-query-params";
import { prepareImgSrc } from "helpers/prepareImgSrc";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import getDisplayLanguage from "helpers/getDisplayLanguage";

function Header(): JSX.Element {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = useQuery(location.search);
    const [query] = useQueryParams({
        survey: StringParam,
    });
    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });
    const { data: participantData } = useGetParticipantInfoQuery();
    const [langFromApi, setLangFromApi] = useState<string | null>(null);

    const lang = i18n.language;
    const langForTranslation = lang.toLowerCase();

    let appTitle = "";
    let smallLogo = "";

    if (!displayInfoLoading) {
        const displayInfo =
            displayInfoData?.value?.displayInformations?.[langForTranslation];
        appTitle = displayInfo?.default?.mainTitle || t("app_title");
        smallLogo = displayInfo?.default?.smallLogo
            ? prepareImgSrc(displayInfo?.default?.smallLogo)
            : Logo;
    }

    useEffect(() => {
        if (displayInfoData && participantData) {
            const langFromAPI = getDisplayLanguage(
                displayInfoData,
                participantData
            );
            if (langFromAPI) {
                setLangFromApi(langFromAPI);
            }
        }
    }, [displayInfoData, participantData]);

    return (
        <Layout.Header className={styles.header}>
            <Row justify="space-between" align="middle">
                <Col>
                    <Link
                        className={styles.logo}
                        to={`/?${queryParams.toString()}`}
                    >
                        {smallLogo ? <img src={smallLogo} alt="" /> : null}
                        <p>{appTitle}</p>
                    </Link>
                </Col>
                <Col>
                    <Space size="large">
                        <LangSelector langFromApi={langFromApi} />
                        <UserMenu />
                    </Space>
                </Col>
            </Row>
        </Layout.Header>
    );
}

export default Header;
