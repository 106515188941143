import { createApi } from "@reduxjs/toolkit/query/react";
import { appBaseQuery } from "config/api.config";

export const accountApi = createApi({
  reducerPath: "account",
  baseQuery: appBaseQuery,
  endpoints: (builder) => ({
    getUserInfo: builder.query<string, void>({
      query: () => "me",
    }),
  }),
});

export const { useGetUserInfoQuery } = accountApi;
