import { createApi } from "@reduxjs/toolkit/query/react";
import { appBaseQuery } from "config/api.config";
import {
    AppointmentDataBody,
    AppointmentTimes,
    CurrentAppointment,
    DisplayInfo,
    LoginData,
    ParticipantInfo,
    ValidationErrors,
} from "src/types/terminportal.types";

export const appointmentAppApi = createApi({
    reducerPath: "Login",
    baseQuery: appBaseQuery,
    endpoints: (builder) => ({
        login: builder.query<boolean, LoginData>({
            query: (params) => ({
                url: "AppointmentApp/login",
                params,
            }),
        }),
        getAppointmentTimes: builder.query<AppointmentTimes, void>({
            query: () => ({
                url: "AppointmentApp/appointmentTimes",
            }),
        }),
        getCurrentAppointment: builder.query<CurrentAppointment, void>({
            query: () => ({
                url: "AppointmentApp/getCurrentAppointment",
            }),
        }),
        logout: builder.query<boolean, void>({
            query: () => ({
                url: "AppointmentApp/logout",
            }),
        }),
        saveAppointment: builder.mutation<
            ValidationErrors,
            AppointmentDataBody
        >({
            query: (requestBody) => ({
                method: "POST",
                url: "AppointmentApp/saveAppointment",
                body: requestBody,
            }),
        }),
        deleteAppointment: builder.mutation<ValidationErrors, void>({
            query: () => ({
                method: "POST",
                url: "AppointmentApp/deleteAppointment",
            }),
        }),
        getDisplayInfo: builder.query<DisplayInfo, { survey: string }>({
            query: ({ survey }) => ({
                url: `AppointmentApp/displayInfo/${survey}`,
            }),
        }),
        getParticipantInfo: builder.query<ParticipantInfo, void>({
            query: () => ({
                url: `AppointmentApp/participantInformation`,
            }),
        }),
    }),
});

export const {
    useLazyLoginQuery,
    useGetAppointmentTimesQuery,
    useGetCurrentAppointmentQuery,
    useLazyLogoutQuery,
    useSaveAppointmentMutation,
    useDeleteAppointmentMutation,
    useGetDisplayInfoQuery,
    useLazyGetDisplayInfoQuery,
    useGetParticipantInfoQuery,
} = appointmentAppApi;
