import {
    Button,
    Card,
    Col,
    Form,
    Input,
    notification,
    Row,
    Space,
    Typography,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import styles from "./Login.module.less";
import {
    useLazyGetDisplayInfoQuery,
    useLazyLoginQuery,
} from "store/terminportal/appointmentApp.api";
import { StringParam, useQueryParams } from "use-query-params";
import setAuthority from "utils/setAuthority";
import { useQuery } from "hooks/useQuery";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { DisplayInfo } from "../../types/terminportal.types";
import Layout from "antd/lib/layout/layout";
import LangSelector from "components/layouts/LangSelector";
import { prepareImgSrc } from "helpers/prepareImgSrc";
import Logo from "assets/logo.icon.png";

const { Title, Text } = Typography;

function Login(): JSX.Element {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [localizedData, setLocalizedData] = useState({
        smallLogo: "",
        loginTitle: "",
        loginDescription: "",
        lblUser: "",
        lblPassword: "",
        btnLogin: "",
    });
    const queryParams = useQuery(location.search);
    const lang = i18n.language;
    const [query] = useQueryParams({
        survey: StringParam,
        user: StringParam,
        password: StringParam,
        origin: StringParam,
    });

    const [data, setData] = useState<DisplayInfo | null>(null);
    const [getDisplayInfo, { isLoading: getDisplayInfoLoading }] =
        useLazyGetDisplayInfoQuery();

    useEffect(() => {
        const handleGetDisplayInfo = async () => {
            try {
                const response = await getDisplayInfo({
                    survey: query.survey!,
                });
                if (response.isSuccess && !response.isError) {
                    setData(response.data);
                }
            } catch (e) {
                console.error(e);
                notification.error({ message: t("something_went_wrong") });
            }
        };
        if (query.survey && query.survey !== "undefined") {
            handleGetDisplayInfo();
        }
    }, [getDisplayInfo, query.survey, t]);

    useEffect(() => {
        if (data) {
            const langForTranslation = lang.toLowerCase();
            const displayInfo =
                data?.value?.displayInformations?.[langForTranslation];
            const pageLoginData = displayInfo?.pageLogin;
            const smallLogo = displayInfo?.default?.smallLogo
                ? prepareImgSrc(displayInfo?.default?.smallLogo)
                : Logo;
            const loginTitle = pageLoginData?.title || `${t("login.title")}`;
            const loginDescription =
                pageLoginData?.infoText || `${t("login.infoText")}`;
            const lblUser =
                pageLoginData?.lblUser || `${t("login.messages.lblUser")}`;
            const lblPassword =
                pageLoginData?.lblPassword ||
                `${t("login.messages.lblPassword")}`;
            const btnLogin =
                pageLoginData?.btnLogin || `${t("login.actions.login")}`;
            setLocalizedData({
                smallLogo,
                loginTitle,
                loginDescription,
                lblUser,
                lblPassword,
                btnLogin,
            });
        }
    }, [data, lang, t]);

    const [login, { isLoading }] = useLazyLoginQuery();

    const handleLogin = async () => {
        try {
            const { validateFields } = form;
            validateFields().then(async (values) => {
                const params = {
                    ...values,
                    survey: query.survey,
                    origin: query.origin,
                    token: process.env.REACT_APP_API_ACCESS_TOKEN,
                };
                const response = await login(params);
                if (response.isSuccess && !response.isError) {
                    setAuthority(true);
                    navigate(`/?${queryParams.toString()}`);
                }
            });
        } catch (e) {
            console.error(e);
            notification.error({ message: t("something_went_wrong") });
        }
    };

    const labelConfig = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <Layout>
            {query.survey && query.survey !== "undefined" ? (
                <>
                    <div className={styles.langSelectorWrapper}>
                        <LangSelector />
                    </div>
                    <Row
                        className={styles.container}
                        justify="center"
                        align="middle"
                    >
                        <Col>
                            <Card
                                title=""
                                loading={getDisplayInfoLoading}
                                className={styles.loginCard}
                                headStyle={{
                                    border: "none",
                                }}
                            >
                                {localizedData.smallLogo ? (
                                    <img
                                        src={localizedData.smallLogo}
                                        className={styles.logo}
                                        alt=""
                                    />
                                ) : null}

                                <Title level={4} className={styles.cardTitle}>
                                    {localizedData.loginTitle}
                                </Title>

                                {localizedData.loginDescription && (
                                    <Space
                                        style={{ marginBottom: "24px" }}
                                        className={styles.cardDescription}
                                    >
                                        <Text>
                                            {parse(
                                                localizedData.loginDescription
                                            )}
                                        </Text>
                                    </Space>
                                )}
                                <Form
                                    name="login_form"
                                    onFinish={handleLogin}
                                    form={form}
                                    initialValues={query}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        name="user"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "login.messages.userNameError"
                                                ),
                                            },
                                        ]}
                                        className={styles.formItem}
                                        label={localizedData.lblUser}
                                        {...labelConfig}
                                    >
                                        <Input
                                            prefix={<UserOutlined />}
                                            bordered={false}
                                            className={styles.formInput}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "login.messages.passwordError"
                                                ),
                                            },
                                        ]}
                                        className={styles.formItem}
                                        label={localizedData.lblPassword}
                                        {...labelConfig}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined />}
                                            bordered={false}
                                            className={styles.formInput}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className={styles.loginButtonFormItem}
                                    >
                                        <Button
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            className={styles.loginButton}
                                            loading={isLoading}
                                        >
                                            {localizedData.btnLogin}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : null}
        </Layout>
    );
}

export default Login;
