import DEFlagIcon from "assets/icons/DEFlagIcon";
import FRFlagIcon from "assets/icons/FRFlagIcon";
import ITFlagIcon from "assets/icons/ITFlagIcon";
import UKFlagIcon from "assets/icons/UKFlagIcon";

export const locales = [
    {
        prefix: "de-CH",
        name: "Deutsch",
        Icon: DEFlagIcon,
    },
    {
        prefix: "fr-CH",
        name: "Français",
        Icon: FRFlagIcon,
    },
    {
        prefix: "it-CH",
        name: "Italiano",
        Icon: ITFlagIcon,
    },
    {
        prefix: "en-US",
        name: "English",
        Icon: UKFlagIcon,
    },
];
