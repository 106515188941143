import View from "components/elements/View";
import { useTranslation } from "react-i18next";
import { useGetDisplayInfoQuery } from "store/terminportal";
import { StringParam, useQueryParams } from "use-query-params";
import parse from "html-react-parser";
import { Space } from "antd";
import i18n from "locales/i18n";

function Contact(): JSX.Element {
    const { t } = useTranslation();
    const [query] = useQueryParams({
        survey: StringParam,
        user: StringParam,
        password: StringParam,
    });

    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });

    const lang = i18n.language;
    const langForTranslation = lang.toLowerCase();

    let pageTitle = "";
    let text = "";
    if (!displayInfoLoading) {
        const displayInfo =
            displayInfoData?.value?.displayInformations?.[langForTranslation];
        const pageContact = displayInfo?.pageContact;
        pageTitle = pageContact?.title || t(`contact.title`);
        text = pageContact?.text || t(`contact.text`);
    }

    return (
        <View
            title={pageTitle}
            withBreadcrumbs={false}
            center
            isLoading={displayInfoLoading}
        >
            <Space size="large">
                <p>{parse(text)}</p>
            </Space>
        </View>
    );
}

export default Contact;
