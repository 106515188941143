import React, { useCallback, useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { appRoutes } from "config/appRoutes.config";
import { routes } from "config/routes.config";
import AppTemplate from "components/templates/AppTemplate";
import getAuthority from "utils/getAuthority";
import { notification } from "antd";
import { useLazyLoginQuery } from "store/terminportal";
import { useTranslation } from "react-i18next";
import setAuthority from "utils/setAuthority";
import ProtectedRoute from "components/elements/ProtectedRoute/ProtectedRoute";

function App(): JSX.Element {
    const { t } = useTranslation();
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

    const [query] = useQueryParams({
        survey: StringParam,
        user: StringParam,
        password: StringParam,
    });

    const [login] = useLazyLoginQuery();

    const callbackHandleLogin = useCallback(async () => {
        try {
            const params = {
                user: query.user || "",
                password: query.password || "",
                survey: query.survey || "",
                token: process.env.REACT_APP_API_ACCESS_TOKEN,
            };
            const response = await login(params);
            if (response.isSuccess && !response.isError) {
                setAuthority(response.isSuccess);
            }
        } catch (e) {
            console.error(e);
            notification.error({ message: t("something_went_wrong") });
        }
    }, [query.user, query.password, query.survey, login, t]);

    useEffect(() => {
        if (query.password && query.user && query.survey) {
            callbackHandleLogin();
        } else {
            setIsAuthorized(getAuthority());
        }
    }, [query, callbackHandleLogin]);

    useEffect(() => {
        const updateAuthority = () => {
            const authority = getAuthority();
            setIsAuthorized(authority);
        };
        document.addEventListener("authorityChange", updateAuthority);

        return () =>
            document.removeEventListener("authorityChange", updateAuthority);
    }, []);

    return (
        <Routes>
            <Route
                element={
                    <ProtectedRoute
                        isAuthorized={isAuthorized}
                        survey={query.survey}
                    >
                        <AppTemplate />
                    </ProtectedRoute>
                }
            >
                {appRoutes.map(({ name, path, Element }) => (
                    <Route key={name} path={path} element={<Element />} />
                ))}
            </Route>
            {routes.map(({ name, path, Element }) => (
                <Route key={name} path={path} element={<Element />} />
            ))}
        </Routes>
    );
}

export default App;
