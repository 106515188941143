import { RouteType } from "types/routes.types";
import Login from "views/Login";
import NotFound from "views/NotFound";
import Logout from "views/Logout";

export const routes: RouteType[] = [
    {
        name: "login",
        path: "/login",
        Element: Login,
    },
    {
        name: "logout",
        path: "/logout",
        Element: Logout,
    },
    {
        name: "notFound",
        path: "*",
        Element: NotFound,
    },
];
