import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, notification } from "antd";

import {
    CaretDownOutlined,
    CaretUpOutlined,
    LoadingOutlined,
    LogoutOutlined,
    UserOutlined,
} from "@ant-design/icons";

import styles from "./UserMenu.module.less";
import { useLazyLogoutQuery } from "store/terminportal";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { afterLogout } from "helpers/logoutHelpers";
import { useDispatch } from "react-redux";

function UserMenu(): JSX.Element {
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [query] = useQueryParams({
        survey: StringParam,
    });
    const [logout, { currentData: logoutData, isLoading, error }] =
        useLazyLogoutQuery();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        try {
            await logout();
        } catch (e) {
            console.error(e);
            notification.error({ message: t("something_went_wrong") });
        }
    };

    useEffect(() => {
        afterLogout(logoutData, error, navigate, query.survey, dispatch);
    }, [logoutData, error, navigate, query.survey, dispatch]);

    const menuItems = [
        {
            key: "logout",
            icon: isLoading ? (
                <LoadingOutlined className={styles.icon} spin={isLoading} />
            ) : (
                <LogoutOutlined className={styles.icon} />
            ),
            label: <span>{t("logout")}</span>,
            onClick: handleLogout,
            disabled: isLoading,
        },
    ];

    return (
        <Dropdown
            trigger={["click"]}
            open={dropdownVisible}
            onOpenChange={() => setDropdownVisible(!dropdownVisible)}
            overlay={<Menu theme="light" items={menuItems} />}
        >
            <div className={styles.dropdown_wrapper}>
                <UserOutlined className={styles.user_icon} />
                {dropdownVisible ? (
                    <CaretUpOutlined className={styles.caret_icon} />
                ) : (
                    <CaretDownOutlined className={styles.caret_icon} />
                )}
            </div>
        </Dropdown>
    );
}

export default UserMenu;
