import i18n from "locales/i18n";

function formatDateTime(
  date: Date,
  type?: "short" | "long",
  weekday?: "short" | "long"
): string {
  const lang = i18n.language;

  if (!date) {
    return i18n.t("no_date");
  }

  const jsDate = new Date(date);

  return jsDate.toLocaleString(lang, {
    year: "numeric",
    month: type === "long" ? "short" : "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    weekday,
  });
}

export default formatDateTime;
