import {
    Alert,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    notification,
    Row,
    Select,
} from "antd";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import parse from "html-react-parser";

import View from "components/elements/View";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styles from "./Terminportal.module.less";

import {
    useDeleteAppointmentMutation,
    useGetAppointmentTimesQuery,
    useGetCurrentAppointmentQuery,
    useGetDisplayInfoQuery,
    useGetParticipantInfoQuery,
    useLazyLogoutQuery,
    useSaveAppointmentMutation,
} from "store/terminportal";
import moment from "moment";
import { AppointmentTimes } from "../../../../types/terminportal.types";
import { DefaultOptionType } from "antd/lib/select";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "views/App/modals/ConfirmationModal";

import IntlTelInput, { CountryData } from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { StringParam, useQueryParams } from "use-query-params";
import i18n from "locales/i18n";
import { locales } from "config/locales.config";
import { useNavigate } from "react-router-dom";
import setAuthority from "utils/setAuthority";
import { InfoCircleOutlined } from "@ant-design/icons";
import InfoModal from "views/App/modals/InfoModal";

function Terminportal(): JSX.Element {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<any>({});
    const [query] = useQueryParams({
        survey: StringParam,
    });
    const lang = i18n.language;
    const navigate = useNavigate();
    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });

    const { data: participantData } = useGetParticipantInfoQuery();

    const [logout] = useLazyLogoutQuery();
    const [saveAppointment, saveAppointmentResult] =
        useSaveAppointmentMutation();
    const [deleteAppointment, deleteAppointmentResult] =
        useDeleteAppointmentMutation();
    const [finalPhoneNumber, setFinalPhoneNumber] = useState<string>("");
    const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
        null
    );

    const [confirmationModalVisible, setConfirmationModalVisible] =
        useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const { data: appointmentTimes } = useGetAppointmentTimesQuery();
    const {
        data: currentAppointment,
        refetch: refetchCurrentAppointment,
        isLoading: isCurrentAppointmentLoading,
    } = useGetCurrentAppointmentQuery();

    const formInputsStateRef = useRef<IntlTelInput>(null);

    const actualDateTime = moment(new Date());
    const actualDate = actualDateTime.format("YYYY-MM-DD");
    const actualTime = actualDateTime.format("HH:mm");

    const setInitialValue = useCallback(() => {
        if (currentAppointment?.value) {
            const appointment = currentAppointment.value.appointment;
            let currentPhoneNumber = currentAppointment.value.phoneNumber ?? "";
            let currentLanguage = currentAppointment.value.language;
            if (currentPhoneNumber?.startsWith("00")) {
                currentPhoneNumber = currentPhoneNumber.replace("00", "+");
            }
            const date = !appointment ? null : moment(appointment);
            const time = !appointment
                ? null
                : moment(appointment).format("HH:mm");
            form.setFieldsValue({
                date,
                time,
                phoneNumber: currentPhoneNumber,
                interviewLang: currentLanguage,
            });
            setData({
                date,
                time,
                phoneNumber: currentPhoneNumber,
                interviewLang: currentLanguage,
            });
            setSelectedDate(date);
        } else {
            setData({
                date: null,
                time: null,
                phoneNumber: "",
                interviewLang: "",
            });
            setSelectedDate(null);
            setFinalPhoneNumber("");
            setPhoneNumberValid(false);
        }
    }, [currentAppointment, form]);

    const onChange = (
        value: DatePickerProps["value"] | RangePickerProps["value"]
    ) => {
        setSelectedDate(value as moment.Moment);
        form.setFieldsValue({
            time: null,
        });
    };

    const onPhoneNumberChange = (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        extension: string
    ) => {
        value = value.replace(/[^+|0-9| ]/g, "");
        value = value.replace(/(?<!^)\+/g, "");
        form.setFieldsValue({
            phoneNumber: value,
        });
        if (isValid) {
            fullNumber = fullNumber.replace("+", "00");
            fullNumber = fullNumber.replaceAll(" ", "");
            setFinalPhoneNumber(fullNumber);
        }
        setPhoneNumberValid(isValid);
    };

    const handleLogout = async (appointment: string) => {
        const date = !appointment
            ? null
            : moment(appointment).format("DD.MM.YYYY");
        const time = !appointment ? null : moment(appointment).format("HH:mm");
        try {
            const payload = await logout();

            if (payload.isSuccess) {
                setAuthority(false);
                navigate(
                    `/logout?survey=${query.survey}&lng=${lang}&date=${date}&time=${time}`
                );
            } else {
                notification.error({ message: t("something_went_wrong") });
                refetchCurrentAppointment();
            }
        } catch (e) {
            console.error(e);
            notification.error({ message: t("something_went_wrong") });
            refetchCurrentAppointment();
        }
    };

    const handleSubmit = async (values: {
        date: moment.Moment;
        time: moment.Moment;
        phoneNumber: string;
        phoneConfirmation: boolean;
        interviewLang: string;
    }) => {
        const { date, time, interviewLang } = values;
        const appointment = moment(
            `${moment(date).format("YYYY-MM-DD")} ${time}`
        ).format("YYYY-MM-DDTHH:mm:ss");

        try {
            console.log({
                appointment: appointment,
                phoneNumber: finalPhoneNumber,
                language: interviewLang,
            });
            const payload = await saveAppointment({
                appointment: appointment,
                phoneNumber: finalPhoneNumber,
                language: interviewLang,
            }).unwrap();

            if (payload.isSuccessful) {
                refetchCurrentAppointment();
                notification.success({
                    message: t("terminportal.messages.saveSuccess"),
                });
                handleLogout(appointment);
            } else {
                notification.error({
                    message: t(`terminportal.requestErrors.${payload.message}`),
                });
                setInitialValue();
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleDeleteAppointment = async () => {
        try {
            const payload = await deleteAppointment().unwrap();

            if (!payload.errors) {
                notification.success({
                    message: t("terminportal.messages.deleteSuccess"),
                });
                refetchCurrentAppointment();
            }
        } catch (error) {
            console.error("Error", error);
        } finally {
            setConfirmationModalVisible(false);
        }
    };

    const handleOnDiscard = () => {
        setInitialValue();
    };

    useEffect(() => {
        setInitialValue();
    }, [currentAppointment, form, setInitialValue]);

    const prepareLangOptions = (languages: string[]): DefaultOptionType[] => {
        const options = languages.map((lang) => {
            const label = locales.find(
                (item) => item.prefix.toLowerCase() === lang
            );
            return {
                label: label?.name || lang,
                value: lang,
            };
        });

        return options;
    };

    const prepareLocalizedData = () => {
        if (displayInfoData) {
            console.log(
                "prepareLocalizedData",
                displayInfoData,
                participantData
            );
            const langForTranslation = lang.toLowerCase();

            const displayInfo =
                displayInfoData?.value?.displayInformations?.[
                    langForTranslation
                ];
            const pageAppointment = displayInfo?.pageAppointment;
            const languages = displayInfoData?.value?.languages || [];

            const participantStartDate =
                participantData?.value?.participantStartDate || "";
            const participantEndDate =
                participantData?.value?.participantEndDate || "";

            return {
                pageTitle:
                    pageAppointment?.title || `${t(`terminportal.title`)}`,
                text: pageAppointment?.text
                    ? pageAppointment.text
                          .replace(
                              "{participantStartDate}",
                              participantStartDate
                          )
                          .replace("{participantEndDate}", participantEndDate)
                    : "",
                lblInterviewLanguage:
                    pageAppointment?.lblInterviewLanguage ||
                    `${t("terminportal.labels.lblInterviewLanguage")}`,
                lblDate:
                    pageAppointment?.lblDate ||
                    `${t("terminportal.labels.lblDate")}`,
                lblTime:
                    pageAppointment?.lblTime ||
                    `${t("terminportal.labels.lblTime")}`,
                lblPhoneNumber:
                    pageAppointment?.lblPhoneNumber ||
                    `${t("terminportal.labels.lblPhoneNumber")}`,
                btnSend:
                    pageAppointment?.btnSend ||
                    `${t("terminportal.actions.btnSend")}`,
                chkConfirmPhoneNumber:
                    pageAppointment?.chkConfirmPhoneNumber ||
                    `${t("terminportal.messages.chkConfirmPhoneNumber")}`,
                interviewLangOptions: prepareLangOptions(languages),
                infoBtnPhoneNumber:
                    pageAppointment?.infoBtnPhoneNumber ||
                    `${t("terminportal.infoModal.description")}`,
            };
        } else {
            return {
                pageTitle: "",
                text: "",
                lblInterviewLanguage: "",
                lblDate: "",
                lblTime: "",
                lblPhoneNumber: "",
                btnSend: "",
                chkConfirmPhoneNumber: "",
                interviewLangOptions: [],
                infoBtnPhoneNumber: "",
            };
        }
    };

    const localizedData = prepareLocalizedData();

    useEffect(() => {
        if (data?.phoneNumber) {
            formInputsStateRef.current?.setInitialState();
        }
    }, [data, form]);

    // time options - a minutes duration
    const callbackGenerateTimeDropdownOptions = useCallback(
        ({
            selectedDate,
            appointmentTimes,
        }: {
            selectedDate: moment.Moment | null;
            appointmentTimes?: AppointmentTimes;
        }): DefaultOptionType[] => {
            if (appointmentTimes && selectedDate) {
                const day = selectedDate.day();
                const selectedDateTimesPerWeekday =
                    appointmentTimes.timesPerWeekday.filter(
                        (time) => time.weekday === day
                    );

                const minutes = 5;
                const timesPerWeekDay = selectedDateTimesPerWeekday.reduce(
                    (acc: string[], curr) => {
                        const startTime = moment(
                            curr.startTime,
                            "HH:mm"
                        ).format("HH:mm");
                        const lastPossibleTime = moment(curr.endTime, "HH:mm")
                            .subtract(minutes, "minutes")
                            .format("HH:mm");

                        acc.push(startTime);
                        while (acc[acc.length - 1] < lastPossibleTime) {
                            const nextStartTime = moment(
                                acc[acc.length - 1],
                                "HH:mm"
                            )
                                .add(minutes, "minutes")
                                .format("HH:mm");
                            acc.push(nextStartTime);
                        }
                        return acc;
                    },
                    []
                );

                const timeslots = timesPerWeekDay.map((time) => {
                    const todayPastTime =
                        actualDate === selectedDate.format("YYYY-MM-DD") &&
                        time < actualTime;

                    return {
                        label: time,
                        value: time,
                        disabled: todayPastTime,
                    };
                });

                return timeslots;
            }
            return [];
        },
        [actualDate, actualTime]
    );

    const handleInfoClick = () => {
        setInfoModalVisible(true);
    };

    const selectedDateTimeOptions = useMemo(
        () =>
            callbackGenerateTimeDropdownOptions({
                selectedDate,
                appointmentTimes,
            }),
        [callbackGenerateTimeDropdownOptions, selectedDate, appointmentTimes]
    );

    const labelConfig = {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    };

    return (
        <View
            title={localizedData.pageTitle}
            withBreadcrumbs={false}
            isLoading={displayInfoLoading || isCurrentAppointmentLoading}
        >
            {currentAppointment?.value?.appointment && (
                <>
                    <Alert
                        type="success"
                        message={
                            t("terminportal.messages.info") +
                            ` ${moment(
                                currentAppointment.value.appointment
                            ).format("DD.MM.YYYY, HH:mm")}`
                        }
                        className={styles.center}
                    />
                    <Divider />
                </>
            )}
            <div className={styles.info}>{parse(localizedData.text)}</div>
            <Form
                layout="horizontal"
                form={form}
                onFinish={handleSubmit}
                initialValues={data}
                requiredMark={false}
                className={styles.form}
            >
                <Row gutter={[20, 20]} justify="center">
                    <Col xs={24} xxl={8}>
                        <Form.Item
                            name="interviewLang"
                            label={localizedData.lblInterviewLanguage}
                            {...labelConfig}
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "terminportal.messages.interviewLangError"
                                    ),
                                },
                            ]}
                            className={styles.space}
                        >
                            <Select
                                showSearch
                                size="large"
                                placeholder={t(
                                    "terminportal.messages.interviewLangPlaceholder"
                                )}
                                options={localizedData.interviewLangOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label={localizedData.lblDate}
                            {...labelConfig}
                            name="date"
                            className={styles.space}
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "terminportal.messages.dateError"
                                    ),
                                },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                size="large"
                                onChange={onChange}
                                placeholder={t(
                                    "terminportal.messages.datePlaceholder"
                                )}
                                disabledDate={(current) => {
                                    return (
                                        !appointmentTimes?.timesPerWeekday.find(
                                            ({ weekday }) =>
                                                weekday === current.day()
                                        ) ||
                                        appointmentTimes?.lockedDates.some(
                                            (lockedDate) => {
                                                return current.isSame(
                                                    moment(lockedDate),
                                                    "day"
                                                );
                                            }
                                        ) ||
                                        current <
                                            moment(
                                                appointmentTimes?.startDate
                                            ).endOf("day") ||
                                        current >
                                            moment(
                                                appointmentTimes?.endDate
                                            ).endOf("day") ||
                                        current.isBefore(actualDateTime, "day")
                                    );
                                }}
                                format="DD.MM.YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="time"
                            className={styles.space}
                            label={localizedData.lblTime}
                            {...labelConfig}
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "terminportal.messages.timeError"
                                    ),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                size="large"
                                placeholder={t(
                                    "terminportal.messages.timePlaceholder"
                                )}
                                options={selectedDateTimeOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phoneNumber"
                            className={styles.space}
                            label={localizedData.lblPhoneNumber}
                            {...labelConfig}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const isFilled = value !== "";
                                        if (isFilled && phoneNumberValid) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                !isFilled
                                                    ? t(
                                                          "terminportal.messages.phoneNumberEmptyError"
                                                      )
                                                    : t(
                                                          "terminportal.messages.phoneNumberError"
                                                      )
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <div className={styles.phoneNumberWrapper}>
                                <IntlTelInput
                                    containerClassName={`allow-dropdown intl-tel-input ${styles.phoneContainer}`}
                                    inputClassName={styles.phoneInput}
                                    defaultCountry="ch"
                                    preferredCountries={["ch", "li"]}
                                    onlyCountries={["ch", "li"]}
                                    autoPlaceholder={false}
                                    placeholder={t(
                                        "terminportal.messages.phoneNumberPlaceholder"
                                    )}
                                    onPhoneNumberChange={onPhoneNumberChange}
                                    onPhoneNumberBlur={onPhoneNumberChange}
                                    telInputProps={{ inputMode: "numeric" }}
                                    ref={formInputsStateRef}
                                    nationalMode={true}
                                    format={true}
                                />

                                <Button
                                    className="ant-btn ant-btn-primary"
                                    icon={<InfoCircleOutlined />}
                                    onClick={handleInfoClick}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="phoneConfirmation"
                            className={styles.checkbox}
                            label={localizedData.chkConfirmPhoneNumber}
                            {...labelConfig}
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (!value) {
                                            return Promise.reject(
                                                t(
                                                    "terminportal.messages.phoneConfirmationError"
                                                )
                                            );
                                        } else {
                                            Promise.resolve();
                                        }
                                    },
                                },
                            ]}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[20, 20]}
                    justify="center"
                    className={styles.buttonsWrapper}
                >
                    <Col xs={24} xxl={4}>
                        <Button block onClick={handleOnDiscard}>
                            {t("terminportal.actions.discard")}
                        </Button>
                    </Col>
                    <Col xs={24} xxl={4}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={saveAppointmentResult.isLoading}
                            block
                        >
                            {localizedData.btnSend}
                        </Button>
                    </Col>
                    {currentAppointment?.value &&
                        appointmentTimes?.allowDelete && (
                            <Col xs={24} xxl={4}>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        setConfirmationModalVisible(true)
                                    }
                                    block
                                >
                                    {t("terminportal.actions.delete")}
                                </Button>
                            </Col>
                        )}
                </Row>
            </Form>
            <Divider />
            <ConfirmationModal
                open={confirmationModalVisible}
                isLoading={deleteAppointmentResult.isLoading}
                title={t("terminportal.confirmationModal.title")}
                description={t("terminportal.confirmationModal.description")}
                onClose={() => setConfirmationModalVisible(false)}
                onConfirm={() => handleDeleteAppointment()}
            />
            <InfoModal
                open={infoModalVisible}
                description={localizedData.infoBtnPhoneNumber}
                onClose={() => setInfoModalVisible(false)}
            />
        </View>
    );
}

export default Terminportal;
