import { RouteType } from "types/routes.types";
import {
    HomeOutlined,
    CalendarOutlined,
    ContactsOutlined,
} from "@ant-design/icons";

import Terminportal from "views/App/views/Terminportal";
import Contact from "views/App/views/Contact";
import Home from "views/App/views/Home";

export const appRoutes: RouteType[] = [
    {
        name: "Home",
        path: "/",
        Element: Home,
        Icon: HomeOutlined,
    },
    {
        name: "Terminportal",
        path: "/terminportal",
        Element: Terminportal,
        Icon: CalendarOutlined,
    },
    {
        name: "Kontakt",
        path: "/kontakt",
        Element: Contact,
        Icon: ContactsOutlined,
    },
];
