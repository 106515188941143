import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean;
    title: string;
    description: string;
    isLoading: boolean;

    onConfirm(): void;

    onClose(): void;
}

function ConfirmationModal({
    open,
    title,
    description,
    isLoading,
    onConfirm,
    onClose,
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <Modal
            title={title}
            open={open}
            onCancel={onClose}
            onOk={onConfirm}
            okButtonProps={{
                loading: isLoading,
            }}
            cancelText={t("no")}
            okText={t("yes")}
            destroyOnClose
        >
            <p>{description}</p>
        </Modal>
    );
}

export default ConfirmationModal;
