import { Layout } from "antd";
import formatDateTime from "helpers/formatDateTime";
import { useTranslation } from "react-i18next";
import buildData from "version";
import styles from "./Footer.module.less";

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Layout.Footer className={styles.wrapper}>
      <p className={styles.copyright}>{t("copyright_text")}</p>
      <p className={styles.version}>
        {buildData.commit === "local" ? (
          <span>local build {formatDateTime(new Date())}</span>
        ) : (
          <span>
            Version #{buildData.commit} Build #{buildData.build}{" "}
            {formatDateTime(new Date(buildData.dateTime))}
          </span>
        )}
      </p>
    </Layout.Footer>
  );
}

export default Footer;
