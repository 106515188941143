import { Button, Result } from "antd";
import View from "components/elements/View";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

function NotFound(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <View>
      <Result
        status="404"
        title="404"
        subTitle={
          <>
            {t("page_not_found")}: <strong>{pathname}</strong>
          </>
        }
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            {t("back_home")}
          </Button>
        }
      />
    </View>
  );
}

export default NotFound;
