import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { appRoutes } from "config/appRoutes.config";
import LinkWithParams from "components/elements/LinkWithParams";

import styles from "./MainMenu.module.less";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";
import { useGetDisplayInfoQuery } from "store/terminportal";
import { useIsExpired } from "hooks/useIsExpired";
import i18n from "i18next";

function MainMenu(): JSX.Element {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [query] = useQueryParams({
        survey: StringParam,
    });
    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });
    const isExpired = useIsExpired();
    const lang = i18n.language;
    const langForTranslation = lang.toLowerCase();

    const displayInfo =
        displayInfoData?.value?.displayInformations?.[langForTranslation];

    let navHome = "";
    let navTerminportal = "";
    let navKontakt = "";

    if (!displayInfoLoading) {
        const navData = displayInfo?.nav;
        navHome = navData?.home || t(`menu.Home`);
        navTerminportal = navData?.appointment || t(`menu.Terminportal`);
        navKontakt = navData?.contact || t(`menu.Kontakt`);
    }

    const prepareName = (name: string) => {
        switch (name) {
            case "Home":
                return navHome;
            case "Terminportal":
                return navTerminportal;
            case "Kontakt":
                return navKontakt;
        }
    };

    const menuItems: MenuItemType[] = [];

    const preparedAppRoutes = isExpired ? [appRoutes[0]] : appRoutes;
    preparedAppRoutes.map(({ name, path, Icon, hideInMenu, external }) =>
        hideInMenu
            ? null
            : menuItems.push({
                  key: path,
                  icon: Icon ? <Icon /> : null,
                  label: external ? (
                      <a href={path} target="_blank" rel="noreferrer">
                          {prepareName(name)}
                      </a>
                  ) : (
                      <LinkWithParams to={path}>
                          {prepareName(name)}
                      </LinkWithParams>
                  ),
              })
    );

    return (
        <Menu
            className={styles.menu}
            theme="light"
            mode="horizontal"
            selectedKeys={[pathname]}
            items={menuItems}
        />
    );
}

export default MainMenu;
