import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import setAuthority from "utils/setAuthority";

/**
 * setAuthority if 401 error
 */
const rtkQueryUnauthorized: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (
            "originalStatus" in action.payload &&
            action.payload.originalStatus === 401
        ) {
            setAuthority(false);
        }
    }

    return next(action);
};

export default rtkQueryUnauthorized;
