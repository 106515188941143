import { IconProps } from "types/icon.types";

function DEFlagIcon(props: IconProps): JSX.Element {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="icon-/-flag-/-de"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="DE">
          <path
            d="M15.5447096,10.6668793 C14.4464192,13.7739479 11.4831708,16 8,16 C4.51682925,16 1.55358082,13.7739479 0.455290373,10.6668793 Z"
            id="Combined-Shape"
            fill="#FDD123"
          />
          <path
            d="M15.5447096,5.33312072 C15.8395615,6.16725793 16,7.06489276 16,8 C16,8.93502569 15.8395895,9.83258548 15.5447868,10.666661 L0.455213226,10.666661 C0.160410533,9.83258548 0,8.93502569 0,8 C0,7.06489276 0.160438518,6.16725793 0.455290373,5.33312072 Z"
            id="Combined-Shape"
            fill="#FF6C69"
          />
          <path
            d="M8,0 C11.4832523,0 14.4465579,2.22615637 15.5447868,5.33333898 L0.455213226,5.33333898 C1.55344205,2.22615637 4.51674769,0 8,0 Z"
            id="Combined-Shape"
            fill="#4F4F4F"
          />
        </g>
      </g>
    </svg>
  );
}

export default DEFlagIcon;
