import { configureStore } from "@reduxjs/toolkit";
import rtkQueryErrorLogger from "middlewares/rtkQueryErrorLogger";
import { accountApi } from "store/account";
import { appointmentAppApi } from "store/terminportal";
import rtkQueryUnauthorized from "middlewares/rtkQueryUnauthorized";

export const store = configureStore({
    reducer: {
        [accountApi.reducerPath]: accountApi.reducer,
        [appointmentAppApi.reducerPath]: appointmentAppApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            accountApi.middleware,
            appointmentAppApi.middleware,
            rtkQueryErrorLogger,
            rtkQueryUnauthorized
        ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
