import { Space } from "antd";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { useGetDisplayInfoQuery } from "store/terminportal/appointmentApp.api";
import { StringParam, useQueryParams } from "use-query-params";
import i18n from "locales/i18n";
import Layout from "antd/lib/layout/layout";
import View from "components/elements/View";
import LangSelector from "components/layouts/LangSelector";
import styles from "./Logout.module.less";

function Logout(): JSX.Element {
    const { t } = useTranslation();

    const lang = i18n.language;
    const [query] = useQueryParams({
        survey: StringParam,
        date: StringParam,
        time: StringParam,
    });
    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });

    const appointmentDate = query.date || "";
    const appointmentTime = query.time || "";

    const prepareLocalizedData = () => {
        if (displayInfoData) {
            const langForTranslation = lang.toLowerCase();
            const displayInfo =
                displayInfoData?.value?.displayInformations?.[
                    langForTranslation
                ];
            const pageLogout = displayInfo?.pageLogout;

            return {
                pageTitle:
                    pageLogout?.title ||
                    `${t(`logoutPage.title`, {
                        appointmentDate,
                        appointmentTime,
                    })}`,
                text:
                    pageLogout?.text ||
                    `${t(`logoutPage.text`, {
                        appointmentDate,
                        appointmentTime,
                    })}`,
            };
        } else {
            return {
                pageTitle: "",
                text: "",
            };
        }
    };

    const localizedData = prepareLocalizedData();
    const preparedText = localizedData.text
        .replace("{appointmentDate}", appointmentDate)
        .replace("{appointmentTime}", appointmentTime);

    return (
        <Layout>
            {query.date && query.time ? (
                <>
                    <div className={styles.langSelectorWrapper}>
                        <LangSelector />
                    </div>
                    <View
                        title={localizedData.pageTitle}
                        withBreadcrumbs={false}
                        center
                        isLoading={displayInfoLoading}
                    >
                        <Space size="large" direction="vertical">
                            <div> {parse(preparedText)}</div>
                        </Space>
                    </View>
                </>
            ) : null}
        </Layout>
    );
}

export default Logout;
