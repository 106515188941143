import moment from "moment";

export const checkIfIsExpired = (participantEndDate?: string) => {
    if (participantEndDate) {
        return moment()
            .startOf("day")
            .isAfter(moment(participantEndDate, "DD.MM.YYYY"));
    }
    return false;
};
