import { Button, Layout } from "antd";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { Outlet } from "react-router-dom";

import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";
import MainMenu from "components/layouts/MainMenu";

import styles from "./AppTemplate.module.less";

function AppTemplate(): JSX.Element {
    const [light, setLight] = useQueryParam(
        "light",
        withDefault(BooleanParam, false)
    );

    const toggleLightMode = () => {
        setLight((prevLight) => !prevLight);
    };

    return (
        <Layout className={styles.layout}>
            {!light && <Header />}
            <Layout>
                {!light && <MainMenu />}
                <Layout>
                    <Outlet />
                    <Footer />
                </Layout>
                <Button
                    className={styles.light_mode_toggle}
                    type="primary"
                    onClick={toggleLightMode}
                    icon={
                        light ? (
                            <FullscreenExitOutlined />
                        ) : (
                            <FullscreenOutlined />
                        )
                    }
                />
            </Layout>
        </Layout>
    );
}

export default AppTemplate;
