import { Link, LinkProps, useLocation } from "react-router-dom";

function LinkWithParams(
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>
): JSX.Element {
  const { search } = useLocation();

  return (
    <Link {...props} to={`${props.to}${search}`}>
      {props.children}
    </Link>
  );
}

export default LinkWithParams;
