import React from "react";
import { Button, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.less";

interface Props {
    open: boolean;
    description?: string;

    onClose(): void;
}

function InfoModal({ open, description, onClose }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <Modal open={open} onCancel={onClose} destroyOnClose footer={false}>
            <p className={styles.description}>{description}</p>
            <Row wrap className={styles.btnWrapper}>
                <Button loading={false} type="primary" onClick={onClose}>
                    {t("ok")}
                </Button>
            </Row>
        </Modal>
    );
}

export default InfoModal;
