import setAuthority from "utils/setAuthority";
import { appointmentAppApi } from "store/terminportal";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "redux";

export function afterLogout(
    logoutData: boolean | undefined,
    error: FetchBaseQueryError | SerializedError | undefined,
    navigate: NavigateFunction,
    survey: string | null | undefined,
    dispatch: Dispatch<any>
) {
    if (logoutData && !error) {
        setAuthority(false);
        navigate(`/login?survey=${survey}`);
        dispatch(appointmentAppApi.util.resetApiState());
    } else if (error) {
        console.error(error);
    }
}
