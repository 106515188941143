import i18n from "locales/i18n";
import { DisplayInfo, ParticipantInfo } from "src/types/terminportal.types";

function mapDimLanguageToCulture(languageFromDim: string){
    switch(languageFromDim){
        case 'DES':
            return 'de-CH'
        case 'FRS':
            return 'fr-CH'
        case 'ITS':
            return 'it-CH'
        case 'ENG':
            return 'en-US'
        default:
            return i18n.language
    }
}

function getDisplayLanguage(displayInfo: DisplayInfo | undefined, participant: ParticipantInfo | undefined) {
    let ret = i18n.language

    if(displayInfo && participant) {
        ret = displayInfo.value.useParticipantLanguage ? mapDimLanguageToCulture(participant.value.language) : i18n.language
    }

    console.log('getDisplayLanguage ret', ret)
    return ret;
}

export default getDisplayLanguage;
