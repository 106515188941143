import { IconProps } from "types/icon.types";

function FRFlagIcon(props: IconProps): JSX.Element {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="icon-/-flag-/-fr"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="IT">
          <path
            d="M5.33333898,0.455213226 L5.33333898,15.5447868 C2.22615637,14.4465579 1.79412041e-13,11.4832523 1.79412041e-13,8 C1.79412041e-13,4.51674769 2.22615637,1.55344205 5.33333898,0.455213226 Z"
            id="Combined-Shape"
            fill="#5884E1"
          />
          <path
            d="M8.00021826,0 C8.93524395,0 9.83280374,0.160410533 10.6668793,0.455213226 L10.6668793,15.5447868 C9.83280374,15.8395895 8.93524395,16 8.00021826,16 L8.06233898,15.999 L7.93800223,15.9997631 C7.02529144,15.992811 6.14896026,15.8330164 5.33333898,15.5447096 L5.33333898,0.455290373 C6.16747619,0.160438518 7.06511101,0 8.00021826,0 Z"
            id="Combined-Shape"
            fill="#E9E9E9"
          />
          <path
            d="M10.6668793,0.455290373 C13.7739479,1.55358082 16,4.51682925 16,8 C16,11.4831708 13.7739479,14.4464192 10.6668793,15.5447096 Z"
            id="Combined-Shape"
            fill="#FF5F63"
          />
        </g>
      </g>
    </svg>
  );
}

export default FRFlagIcon;
