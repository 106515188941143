import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { notification } from "antd";
import i18n from "locales/i18n";

/**
 * Log an error and show a notification!
 */
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        let message = "";
        let description = "";

        if (
            action.payload?.status === 401 ||
            action.payload?.originalStatus === 401
        ) {
            description = `${i18n.t("login.messages.unauthorized")}`;
        } else {
            message = action.payload.data?.title
                ? `Error ${action.payload.status} ${action.payload.data.title}`
                : `Error ${action.payload.status}`;
            description = action.payload.data?.isError
                ? action.payload.data.errorMessage
                : JSON.stringify(action.payload.data);
        }

        notification.error({
            message,
            description,
        });
    }

    return next(action);
};

export default rtkQueryErrorLogger;
