import View from "components/elements/View";
import {
    useGetDisplayInfoQuery,
    useGetParticipantInfoQuery,
    useLazyLogoutQuery,
} from "store/terminportal";
import { useTranslation } from "react-i18next";
import "react-intl-tel-input/dist/main.css";
import { StringParam, useQueryParams } from "use-query-params";
import { prepareImgSrc } from "helpers/prepareImgSrc";
import { Button, notification, Space, Typography } from "antd";
import LinkWithParams from "components/elements/LinkWithParams";
import parse from "html-react-parser";
import styles from "./Home.module.less";
import MainLogo from "assets/mainLogo.icon.png";
import {
    DisplayInformations,
    PageHome,
} from "../../../../types/terminportal.types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsExpired } from "hooks/useIsExpired";
import { useDispatch } from "react-redux";
import { afterLogout } from "helpers/logoutHelpers";
import i18n from "locales/i18n";

function Home(): JSX.Element {
    const { t } = useTranslation();
    const [query] = useQueryParams({
        survey: StringParam,
    });

    const { data: displayInfoData, isLoading: displayInfoLoading } =
        useGetDisplayInfoQuery({
            survey: query.survey!,
        });
    const { data: participantData, isLoading: participantDataLoading } =
        useGetParticipantInfoQuery();
    const [logout, { currentData: logoutData, isLoading, error }] =
        useLazyLogoutQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isExpired = useIsExpired();
    const lang = i18n.language;

    useEffect(() => {
        afterLogout(logoutData, error, navigate, query.survey, dispatch);
    }, [logoutData, error, navigate, query.survey, dispatch]);

    const langForTranslation = lang.toLowerCase();

    let pageTitle = "";
    let mainLogo = "";
    let btnSelectAppointment = "";
    let welcomeText = "";

    const getPageTitle = (
        pageHome?: PageHome,
        displayInfo?: DisplayInformations
    ) => {
        let title;
        if (displayInfo?.pageExpired?.title && isExpired) {
            title = displayInfo.pageExpired.title;
        } else {
            title = pageHome?.title || t(`home.title`);
        }
        return title;
    };

    const getWelcomeText = (
        pageHome?: PageHome,
        displayInfo?: DisplayInformations
    ) => {
        const participantInfo = participantData?.value;
        const firstName = participantInfo?.firstName || "";
        const lastName = participantInfo?.lastName || "";
        const salutation = participantInfo?.salutation || "";

        let welcomeText;

        if (displayInfo && isExpired) {
            welcomeText = displayInfo.pageExpired.text;
        } else {
            welcomeText =
                pageHome?.welcomeText ||
                t(`home.welcomeText`, { salutation, firstName, lastName });
        }

        return welcomeText
            ? welcomeText
                  ?.replace("{salutation}", salutation)
                  .replace("{firstName}", firstName)
                  .replace("{lastName}", lastName)
            : "";
    };

    if (!displayInfoLoading && !participantDataLoading) {
        const displayInfo =
            displayInfoData?.value?.displayInformations?.[langForTranslation];
        const pageHome = displayInfo?.pageHome;
        pageTitle = getPageTitle(pageHome, displayInfo);
        btnSelectAppointment =
            pageHome?.btnSelectAppointment || t(`home.btnSelectAppointment`);
        welcomeText = getWelcomeText(pageHome, displayInfo);
        mainLogo = displayInfo?.default?.mainLogo
            ? prepareImgSrc(displayInfo?.default?.mainLogo)
            : MainLogo;
    }

    const handleLogout = async () => {
        try {
            await logout();
        } catch (e) {
            console.error(e);
            notification.error({ message: t("something_went_wrong") });
        }
    };

    return (
        <View
            title=""
            withBreadcrumbs={false}
            center
            isLoading={displayInfoLoading || participantDataLoading}
        >
            <Space size="large" direction="vertical">
                {mainLogo ? (
                    <img src={mainLogo} className={styles.logo} alt="" />
                ) : null}
                <Typography.Title level={4} className={styles.title}>
                    {pageTitle}
                </Typography.Title>
                <p> {parse(welcomeText)}</p>
                {isExpired ? (
                    <Button
                        type="primary"
                        onClick={handleLogout}
                        loading={isLoading}
                    >
                        {t("logout")}
                    </Button>
                ) : (
                    <LinkWithParams to={"/terminportal"}>
                        <Button type="primary">{btnSelectAppointment}</Button>
                    </LinkWithParams>
                )}
            </Space>
        </View>
    );
}

export default Home;
