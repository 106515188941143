function setAuthority(authorized: boolean): void {
    if (authorized) {
        localStorage.setItem("AUTHORIZED", "true");
    } else {
        localStorage.removeItem("AUTHORIZED");
    }
    const event = new Event("authorityChange");
    document.dispatchEvent(event);
}

export default setAuthority;
